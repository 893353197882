import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            Hi Everyone, I am <span className="purple">Rafa García </span>
            from <span className="purple"> Córdoba, Spain.</span>
            <br />
            I am currently studying Software Engineering at University of Cordoba (UCO).
            <br />
            I have completed high formative cycle on Sysadmin in 2021 and then I joined the University to study Software Engineering.
            <br />
            <br />
            Apart from coding, some other activities that I love to do!
          </p>
          <ul>
            <li className="about-activity">
              <ImPointRight /> Playing videogames
            </li>
            <li className="about-activity">
              <ImPointRight /> Researching potential new technologies
            </li>
            <li className="about-activity">
              <ImPointRight /> Travelling (who else not?)
            </li>
            <li className="about-activity">
              <ImPointRight />... and dogs! 🐶
            </li>
          </ul>

          <p style={{ color: "rgb(155 126 172)" }}>
            "People are always afraid of change. You were afraid of electricity when it was invented, weren't you?"{" "}
          </p>
          <footer className="blockquote-footer">Bill Gates</footer>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
